@import '@wbk/tailwindcss/styles.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Cairo */
@font-face {
  font-family: 'cairo';
  src: local('cairo'), url(/fonts/cairo/regular.ttf);
  font-display: swap;
  font-weight: normal;
}
@font-face {
  font-family: 'cairo';
  src: local('cairo'), url(/fonts/cairo/bold.ttf);
  font-display: swap;
  font-weight: 600 700;
}
@font-face {
  font-family: 'cairo';
  src: local('cairo'), url(/fonts/cairo/black.ttf);
  font-display: swap;
  font-weight: 800 900;
}

/* Base */
@layer base {
  :root {
    --color-primary-100: 55 86% 85%;
    --color-primary-200: 55 100% 80%;
    --color-primary-300: 55 100% 70%;
    --color-primary-400: 55 100% 60%;
    --color-primary-500: 55 100% 50%;
    --color-primary-600: 56 80% 45%;
    --color-primary-700: 55 100% 40%;
    --color-primary-800: 55 100% 30%;
    --color-primary-900: 55 100% 20%;
    --color-primary-contrast: 0 0% 0%;

    --color-secondary-400: 215 66% 30%;
    --color-secondary-500: 215 100% 22%;
    --color-secondary-600: 214 100% 17%;
    --color-secondary-contrast: 0 0% 100%;

    --color-tertiary-400: 227 73% 61%;
    --color-tertiary-500: 226 84% 43%;
    --color-tertiary-600: 227 77% 36%;
    --color-tertiary-contrast: 0 0% 100%;

    --color-action-400: 55 100% 60%;
    --color-action-500: 55 100% 50%;
    --color-action-600: 56 80% 45%;
    --color-action-contrast: 0 0% 0%;

    /* Text */
    --color-text-primary: 0 0% 98%;
    --color-text-secondary: 240 1% 77%;
    --color-text-tertiary: 240 0% 57%;
    --color-text-disabled: 0 0 30%;
    --color-text-link: var(--color-primary-500);

    /** Input */
    --input-bg: 215 66% 20%;
    --input-bg-hover: 215 58% 23%;
    --input-bg-disabled: 215 55% 17%;
    --input-border: 215 33% 33%;
    --input-border-focus: 0 0% 100%;
    --input-popover-bg: 215 100% 20%;

    /** Body */
    --bg-body: 216 100% 15%;
    --bg-body-level-1: 215 66% 20%;
    --bg-body-level-2: 215 66% 25%;
    /* Body inverse */
    --bg-body-light: 216 100% 15%;
    --bg-body-dark: 0 0% 100%;

    /** Paper */
    --paper-bg: 216 100% 15%;
    --paper-bg-level-1: 215 66% 20%;
    --paper-bg-level-2: 215 66% 25%;
    --paper-bg-level-3: 215 66% 30%;
    --paper-border: 216 56% 43%;
    --paper-border-level-1: 216 46% 43%;
    --paper-border-level-2: 216 36% 33%;
    --paper-border-level-3: 216 36% 23%;
  }

  h1 {
    @apply text-4xl font-bold;
  }
  h2 {
    @apply text-3xl font-bold;
  }
  h3 {
    @apply text-2xl font-bold;
  }
  h4 {
    @apply text-xl font-bold;
  }
  h5 {
    @apply text-lg font-bold;
  }
  h6 {
    @apply font-bold;
  }

  [type='checkbox']:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }
}

/** Defaults */
body #__next {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#__next main {
  flex: 1 0 100%;
}

/** 
  Captcha Override
 */
.grecaptcha-badge {
  display: none !important;
}

/* 
  NProgress Override
* /
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
  /*
   @apply hidden tablet:block;
   */
}

#nprogress .bar {
  opacity: 0.8;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;

  @apply bg-tertiary-400 md:bg-primary;
}

/**
  Safe Bottom
*/
.safe-bottom {
  padding-bottom: env(safe-area-inset-bottom);
}

.mini-scrollbar::-webkit-scrollbar {
  width: 0.4em;
}

.mini-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.mini-scrollbar::-webkit-scrollbar-thumb {
  background-color: theme('colors.body.level-2');
  outline: none;
}
