@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base */
@layer base {
  :root {
    --color-primary-100: 338 78% 72%;
    --color-primary-200: 338 79% 67%;
    --color-primary-300: 338 78% 62%;
    --color-primary-400: 338 79% 58%;
    --color-primary-500: 338 78% 53%;
    --color-primary-600: 338 70% 47%;
    --color-primary-700: 338 70% 42%;
    --color-primary-800: 338 70% 37%;
    --color-primary-900: 338 70% 32%;
    --color-primary-contrast: 0 0% 100%;

    /* Secondary */
    --color-secondary-100: 24 100% 94%;
    --color-secondary-200: 24 100% 89%;
    --color-secondary-300: 24 100% 83%;
    --color-secondary-400: 24 100% 77%;
    --color-secondary-500: 24 100% 71%;
    --color-secondary-600: 24 70% 58%;
    --color-secondary-700: 24 70% 45%;
    --color-secondary-800: 24 70% 32%;
    --color-secondary-900: 24 70% 19%;
    --color-secondary-contrast: 0 0% 0%;

    /* Tertiary */
    --color-tertiary-100: 72 82% 89%;
    --color-tertiary-200: 72 82% 76%;
    --color-tertiary-300: 72 82% 63%;
    --color-tertiary-400: 72 82% 50%;
    --color-tertiary-500: 72 100% 44%;
    --color-tertiary-600: 72 100% 35%;
    --color-tertiary-700: 72 100% 27%;
    --color-tertiary-800: 72 100% 17%;
    --color-tertiary-900: 72 100% 8%;
    --color-tertiary-contrast: 0 0% 0%;

    /* Action */
    --color-action-100: 0 0% 84%;
    --color-action-200: 0 0% 88%;
    --color-action-300: 0 0% 91%;
    --color-action-400: 0 0% 94%;
    --color-action-500: 0 0% 0%;
    --color-action-600: 0 0% 0%;
    --color-action-700: 0 0% 0%;
    --color-action-800: 0 0% 0%;
    --color-action-900: 0 0% 0%;
    --color-action-contrast: 0 0% 100%;

    /* Notice */
    --color-notice-100: 23 11% 47%;
    --color-notice-200: 23 15% 38%;
    --color-notice-300: 23 23% 29%;
    --color-notice-400: 23 35% 21%;
    --color-notice-500: 23 70% 12%;
    --color-notice-600: 24 70% 11%;
    --color-notice-700: 23 71% 9%;
    --color-notice-800: 24 71% 8%;
    --color-notice-900: 23 72% 7%;
    --color-notice-light: 24 73% 23%;
    --color-notice-contrast: 24 100% 66%;

    /* Error */
    --color-error-100: 0 100% 93%;
    --color-error-200: 0 100% 86%;
    --color-error-300: 0 100% 79%;
    --color-error-400: 0 100% 72%;
    --color-error-500: 0 100% 65%;
    --color-error-600: 0 70% 52%;
    --color-error-700: 0 70% 39%;
    --color-error-800: 0 70% 26%;
    --color-error-900: 0 70% 13%;
    --color-error-contrast: 0 0% 100%;

    /* Success */
    --color-success-100: 141 84% 93%;
    --color-success-200: 141 79% 85%;
    --color-success-300: 142 77% 73%;
    --color-success-400: 142 69% 58%;
    --color-success-500: 142 71% 45%;
    --color-success-600: 142 76% 36%;
    --color-success-700: 142 72% 29%;
    --color-success-800: 143 64% 24%;
    --color-success-900: 144 61% 20%;
    --color-success-contrast: 0 0% 100%;

    /* Text */
    --color-text-primary: 240 6% 10%;
    --color-text-secondary: 240 5% 34%;
    --color-text-tertiary: 240 5% 65%;
    --color-text-disabled: 0 0 30%;
    --color-text-link: var(--color-primary-500);

    /** Font */
    --typography-display-L: 96px;
    --typography-display-M: 52px;
    --typography-display-S: 44px;
    --typography-display-XS: 40px;
    --typography-display-weight: 700;
    --typography-display-font: inherit;

    --typography-heading-XXL: 36px;
    --typography-heading-XL: 32px;
    --typography-heading-L: 28px;
    --typography-heading-M: 24px;
    --typography-heading-S: 20px;
    --typography-heading-XS: 18px;
    --typography-heading-XXS: 16px;
    --typography-heading-weight: 600;
    --typography-heading-font: inherit;

    --typography-body-XL: 20px;
    --typography-body-L: 18px;
    --typography-body-M: 16px;
    --typography-body-S: 14px;
    --typography-body-XS: 12px;
    --typography-body-XXS: 10px;
    --typography-body-weight: 400;
    --typography-body-font: inherit;

    --typography-label-weight: 500;
    --typography-label-font: inherit;

    --typography-link-weight: 600;
    --typography-link-font: inherit;

    /** Input */
    --input-bg: 0 0% 100%;
    --input-bg-hover: 0 0% 95%;
    --input-bg-disabled: 0 0% 80%;
    --input-border: 0 0% 80%;
    --input-border-focus: 0 0% 0%;
    --input-popover-bg: 0 0% 95%;

    /** Paper */
    --paper-bg: 0 0% 100%;
    --paper-bg-level-1: 0 0% 89%;
    --paper-bg-level-2: 0 0% 78%;
    --paper-bg-level-3: 0 0% 67%;
    --paper-border: 0 0% 89%;
    --paper-border-level-1: 0 0% 78%;
    --paper-border-level-2: 0 0% 67%;
    --paper-border-level-3: 0 0% 56%;

    /** Padding */
    --spacing-L: 24px;
    --spacing-M: 16px;
    --spacing-S: 12px;
    --spacing-XS: 8px;
    --spacing-XXS: 4px;

    /** Radius */
    --radius-L: 24px;
    --radius-M: 12px;
    --radius-S: 8px;
    --radius-XS: 4px;

    /** Body */
    --bg-body: 0 0% 100%;
    --bg-body-level-1: 0 0% 89%;
    --bg-body-level-2: 0 0% 78%;
    --bg-body-level-3: 0 0% 67%;

    /* Body inverse */
    --bg-body-light: 0 0% 100%;
    --bg-body-dark: 0 0% 0%;
  }

  :root[class~='dark'] {
    /* Action */
    --color-action-100: 0 0% 100%;
    --color-action-200: 0 0% 100%;
    --color-action-300: 0 0% 100%;
    --color-action-400: 0 0% 100%;
    --color-action-500: 0 0% 100%;
    --color-action-600: 0 0% 98%;
    --color-action-700: 0 0% 88%;
    --color-action-800: 0 0% 78%;
    --color-action-900: 0 0% 68%;
    --color-action-contrast: 0 0% 0%;

    /* Text */
    --color-text-primary: 0 0% 100%;
    --color-text-secondary: 240 5% 65%;
    --color-text-tertiary: 240 4% 46%;
    --color-text-disabled: 0 0% 25%;
    --color-text-link: var(--color-primary-500);

    /** Input */
    --input-bg: 0 0% 0%;
    --input-bg-hover: 0 0% 5%;
    --input-bg-disabled: 0 0% 10%;
    --input-border: 0 0% 20%;
    --input-border-focus: 0 0% 100%;
    --input-popover-bg: 240 6% 10%;

    /** Paper */
    --paper-bg: 0 0% 0%;
    --paper-bg-level-1: 0 0% 14%;
    --paper-bg-level-2: 0 0% 28%;
    --paper-bg-level-3: 0 0% 42%;
    --paper-border: 0 0% 14%;
    --paper-border-level-1: 0 0% 28%;
    --paper-border-level-2: 0 0% 42%;
    --paper-border-level-3: 0 0% 56%;

    /** Body */
    --bg-body: 0 0% 0%;
    --bg-body-level-1: 0 0% 14%;
    --bg-body-level-2: 0 0% 28%;
    --bg-body-level-3: 0 0% 42%;
    /* Body inverse */
    --bg-body-light: 0 0% 0%;
    --bg-body-dark: 0 0% 100%;

    input {
      color-scheme: dark;
    }
  }

  input::-webkit-search-cancel-button {
    cursor: pointer;
  }
}

/** Defaults */
body {
  @apply bg-body text-text;
}
